// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

// Ensure there's a container in your public/index.html with id="root"
const container = document.getElementById('root');
const root = createRoot(container);

// Unregister all service workers
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(registration => {
      registration.unregister().then(success => {
        console.log('Service worker unregistered:', registration, success);
      }).catch(error => {
        console.error('Error unregistering service worker:', error);
      });
    });
  }).catch(error => {
    console.error('Error fetching service worker registrations:', error);
  });
}

if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
  window.navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(r => r.unregister())
  })
}

// Delete all caches from Cache Storage
if ('caches' in window) {
  caches.keys().then(cacheNames => {
    cacheNames.forEach(cacheName => {
      caches.delete(cacheName).then(success => {
        if (success) {
          console.log('Cache deleted:', cacheName);
        } else {
          console.warn('Cache not deleted:', cacheName);
        }
      });
    });
  }).catch(error => {
    console.error('Error clearing caches:', error);
  });
}


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
