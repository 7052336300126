import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Tooltip from '@mui/material/Tooltip';
// import newsData from './content/news.json';
import publicationsData from './content/publications.json';

const theme = createTheme({
  typography: {
    fontFamily: 'Charter, serif',
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem',
        },
      },
    },
  },
});

function App() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => {
        registration.unregister().then(success => {
          if (success) {
            console.log('Service worker unregistered:', registration);
          }
        });
      });
    }).catch(error => {
      console.error('Error unregistering service workers:', error);
    });
  }


  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [hoverAvatar, setHoverAvatar] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>

        {/* Top Bar */}
        <AppBar color="transparent" position="fixed" elevation={0}>
          <Toolbar sx={{ backgroundColor: '#f0f0f0', borderBottom: '1.2px solid #f0f0f0' }}>
            <Box sx={{ flexGrow: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              <img
                src="favicon.ico"
                alt="Bingxuan Li"
                style={{ width: 30, height: 30, borderRadius: 2, marginRight: 10 }}
              />
              <Typography variant="h6"><b>Bingxuan Li</b></Typography>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Link href="#home" color="inherit" underline="none" sx={{ mx: 2 }}>Home</Link>
              <Link href="#publications" color="inherit" underline="none" sx={{ mx: 2 }}>Publications</Link>
              <Link href="#education" color="inherit" underline="none" sx={{ mx: 2 }}>Education</Link>
              <Link href="#research" color="inherit" underline="none" sx={{ mx: 2 }}>Research</Link>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="left"
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        >
          <Box sx={{ width: 200 }}>
            <List>
              <ListItem button component="a" href="#home" onClick={() => setMobileMenuOpen(false)}>
                <ListItemText primary="Home" sx={{ color: "black" }} />
              </ListItem>
              <ListItem button component="a" href="#publications" onClick={() => setMobileMenuOpen(false)}>
                <ListItemText primary="Publications" sx={{ color: "black" }} />
              </ListItem>
              <ListItem button component="a" href="#education" onClick={() => setMobileMenuOpen(false)}>
                <ListItemText primary="Education" sx={{ color: "black" }} />
              </ListItem>
              <ListItem button component="a" href="#research" onClick={() => setMobileMenuOpen(false)}>
                <ListItemText primary="Research" sx={{ color: "black" }} />
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Toolbar />

        {/* Home */}
        <Box style={{ flex: 1 }}>
          <Box id="home" className="home" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: 4, gap: 5, marginLeft: 5, marginRight: 5 }}>
            <Avatar
              alt="Profile Picture"
              src={hoverAvatar ? "logo.jpg" : "profile_recent.jpg"}
              sx={{ width: { xs: 120, md: 200 }, height: { xs: 120, md: 200 } }}
              onMouseEnter={() => setHoverAvatar(true)}
              onMouseLeave={() => setHoverAvatar(false)}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'left' }}>
              <Typography variant="h3" gutterBottom>
                Hey! <img src="waving-hand.png" alt="Heyy" style={{ width: 45, height: 45 }} />
                <br />
                This is Bingxuan Li.
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <Tooltip title="Google Scholar">
                  <IconButton href="https://scholar.google.com/citations?user=l5Qe_mkAAAAJ&hl=zh-CN" target="_blank" color="inherit" size="large">
                    <img src="icons/google_scholar_icon.png" alt="Google Scholar" style={{ width: 30, height: 30 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="LinkedIn">
                  <IconButton href="https://www.linkedin.com/in/bingxuanli" target="_blank" color="inherit" size="large">
                    <LinkedInIcon color="black" sx={{ width: 30, height: 30 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Github">
                  <IconButton href="https://github.com/BX126" target="_blank" color="inherit" size="large">
                    <GitHubIcon color="black" sx={{ width: 30, height: 30 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Email">
                  <IconButton href="mailto:bx3393@gmail.com" color="inherit" size="large">
                    <EmailIcon sx={{ width: 30, height: 30 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>

          {/* About */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2, marginLeft: 5, marginRight: 5, marginBottom: 1 }}>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h6">
                I'm currently a master student at the University of California, Los Angeles (UCLA), advised by <a href='https://violetpeng.github.io' target="_blank" rel="noreferrer">Professor Nanyun Peng</a> and <a href='http://web.cs.ucla.edu/~kwchang/' target="_blank" rel="noreferrer">Professor Kai-Wei Chang</a>, and mentored by <a href='https://wangywust.github.io' target="_blank" rel="noreferrer">Professor Yiwei Wang</a>.
                Before that, I completed my bachelor's degree at Purdue University, where I double-majored in Computer Science and Data Science.
                I have fortune to be mentored and work with <a href='https://www.bingxinzhao.com' target="_blank" rel="noreferrer">Professor Bingxin Zhao</a>, <a href='https://web.ics.purdue.edu/~shi178/' target="_blank" rel="noreferrer">Professor Pengyi Shi</a>, <a href='https://www.chicagobooth.edu/faculty/directory/w/amy-ward' target="_blank" rel="noreferrer">Professor Amy Ward</a>, and <a href='https://tianyi-zhang.github.io' target="_blank" rel="noreferrer">Professor Tianyi Zhang</a>.
              </Typography>
              <Typography variant="h6">
                My current research work focus on three-fold:
              </Typography>
              <Typography variant="h6">
                <ul>
                  <li><b>Controllable Generation</b></li>
                  <li><b>Multimodal Understanding and Reasoning</b></li>
                  <li><b>AI for Science</b>, including AI for Biomedical and Medicine Scientific Discovery, and AI for Healthcare.</li>
                </ul>
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Publications */}
        <Box style={{ flex: 1 }}>
          <Box id="publications" className="publications" sx={{ marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 2 }}>
            <Typography variant="h4">
              <b>📚 Selected Publications</b>
            </Typography>
            <Typography variant="h6" gutterBottom marginBottom={2}>
              For the full list of publications, please visit my <Link href="https://scholar.google.com/citations?user=l5Qe_mkAAAAJ&hl=zh-CN" target="_blank">Google Scholar</Link> page.
            </Typography>

            {/* Preprint Section */}
            <Typography variant="h5" gutterBottom>
              <b>Under-review Pre-prints</b>
            </Typography>
            <List>
              {publicationsData.filter(publication => publication.tags.includes("Pre-print")).map((publication, index) => (
                <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'flex-start', marginBottom: 3, backgroundColor: '#f5f5f5', padding: 1, borderRadius: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, marginBottom: 1 }}>
                    {publication.tags.map((tag, tagIndex) => (
                      <Box
                        key={tagIndex}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          backgroundColor: 'white',
                          padding: 1,
                          borderRadius: 5,
                          border: tagIndex === 0 ? '4px solid #7ba97c' : '4px solid #f0f0f0'
                        }}
                      >
                        <Typography variant="body2"><b>{tag}</b></Typography>
                      </Box>
                    ))}
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{
                      display: 'flex',
                      width: 220,
                      height: 150,
                      flexGrow: 1,
                      borderRadius: 3,
                      overflow: 'hidden',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #f0f0f0',
                      backgroundColor: 'white',
                    }}
                    >
                      <img src={publication.tesear} alt="arxiv" style={{ width: "95%", height: "auto" }} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          p: ({ node, ...props }) => <Typography variant="h6" sx={{ lineHeight: 1.3, marginBottom: 1, marginLeft: 1 }} {...props} />,
                        }}
                      >
                        {publication.title}
                      </ReactMarkdown>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          p: ({ node, ...props }) => <Typography variant="body1" sx={{ lineHeight: 1.3, marginBottom: 1, marginLeft: 1 }} {...props} />,
                        }}
                      >
                        {publication.authors}
                      </ReactMarkdown>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          p: ({ node, ...props }) => <Typography variant="body1" sx={{ lineHeight: 1.3, marginLeft: 1 }} {...props} />,
                        }}
                      >
                        {publication.detail}
                      </ReactMarkdown>
                      {expandedIndex === index && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 2, marginLeft: 1 }}>
                          <Typography variant="body1" sx={{ lineHeight: 1.3, mb: 1 }}><i>Abstract</i></Typography>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            components={{
                              p: ({ node, ...props }) => <Typography variant="body2" sx={{ lineHeight: 1.3 }} {...props} />,
                            }}
                          >
                            {publication.abstract}
                          </ReactMarkdown>
                        </Box>
                      )}
                      <Box sx={{ display: 'flex', gap: 1, marginTop: 2, flexWrap: 'wrap' }}>
                        <Button onClick={() => setExpandedIndex(expandedIndex === index ? -1 : index)} variant="outlined" color="primary" sx={{ textTransform: 'none', fontWeight: "bold" }} size="small">
                          Abstract
                        </Button>
                        {publication.links.map((link, linkIndex) => (
                          <Button key={linkIndex} href={link.url} target="_blank" variant="outlined" color="primary" sx={{ textTransform: 'none', fontWeight: "bold" }} size="small">
                            {link.label}
                          </Button>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>

            {/* Peer-reviewed Section */}
            <Typography variant="h5" gutterBottom>
              <b>Peer-reviewed Publications</b>
            </Typography>
            <List>
              {publicationsData.filter(publication => !publication.tags.includes("Pre-print")).map((publication, index) => (
                <ListItem key={index} sx={{ flexDirection: 'column', alignItems: 'flex-start', marginBottom: 2, backgroundColor: '#f5f5f5', padding: 1, borderRadius: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, marginBottom: 1 }}>
                    {publication.tags.map((tag, tagIndex) => (
                      <Box
                        key={tagIndex}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          backgroundColor: 'white',
                          padding: 1,
                          borderRadius: 5,
                          border: tagIndex === 0 ? '4px solid #7ba97c' : '4px solid #f0f0f0'
                        }}
                      >
                        <Typography variant="body2"><b>{tag}</b></Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        width: "200px",
                        height: "150px",
                        flexGrow: 1,
                        borderRadius: 3,
                        overflow: 'hidden',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #f0f0f0',
                        backgroundColor: 'white',
                      }}
                    >
                      <img
                        src={publication.tesear}
                        alt="arxiv"
                        style={{
                          width: "95%",
                          height: "auto",
                        }}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          p: ({ node, ...props }) => <Typography variant="h6" sx={{ lineHeight: 1.3, marginBottom: 1, marginLeft: 1 }} {...props} />,
                        }}
                      >
                        {publication.title}
                      </ReactMarkdown>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          p: ({ node, ...props }) => <Typography variant="body1" sx={{ lineHeight: 1.3, marginBottom: 1, marginLeft: 1 }} {...props} />,
                        }}
                      >
                        {publication.authors}
                      </ReactMarkdown>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        components={{
                          p: ({ node, ...props }) => <Typography variant="body1" sx={{ lineHeight: 1.3, marginLeft: 1 }} {...props} />,
                        }}
                      >
                        {publication.detail}
                      </ReactMarkdown>
                      {expandedIndex === index && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 2, marginLeft: 1 }}>
                          <Typography variant="body1" sx={{ lineHeight: 1.3, mb: 1 }}><i>Abstract</i></Typography>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            components={{
                              p: ({ node, ...props }) => <Typography variant="body2" sx={{ lineHeight: 1.3 }} {...props} />,
                            }}
                          >
                            {publication.abstract}
                          </ReactMarkdown>
                        </Box>
                      )}
                      <Box sx={{ display: 'flex', gap: 1, marginTop: 2, flexWrap: 'wrap' }}>
                        <Button onClick={() => setExpandedIndex(expandedIndex === index ? -1 : index)} variant="outlined" color="primary" sx={{ textTransform: 'none', fontWeight: "bold" }} size="small">
                          Abstract
                        </Button>
                        {publication.links.map((link, linkIndex) => (
                          <Button key={linkIndex} href={link.url} target="_blank" variant="outlined" color="primary" sx={{ textTransform: 'none', fontWeight: "bold" }} size="small">
                            {link.label}
                          </Button>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>

            <Typography variant="h6" gutterBottom>
              <b>Services: </b> Reviewing for NeurIPS2024, ICLR2025, AISTATS2025, ICML2025
            </Typography>
          </Box>
        </Box>

        {/* Education */}
        <Box style={{ flex: 1 }}>
          <Box id="education" className="education" sx={{ marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 2 }}>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
              <b>🏫 Education</b>
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Card elevation={0} sx={{ borderRadius: 2, padding: 0, border: '1px solid #f0f0f0' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6"> <b>University of California, Los Angeles</b> | <i>Los Angeles, CA</i> </Typography>
                  </Box>
                  <Box sx={{ marginTop: 0.5 }}>
                    <Typography variant="body1" sx={{ color: 'black' }}>
                      September, 2023 - December, 2024
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1rem', marginTop: 0.5 }}>
                      Master of Engineering in Artificial Intelligence
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.9rem', marginTop: 0.5 }}>
                      GPA: 3.95 / 4.0
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, marginTop: 1 }}>
                    <EmojiEventsIcon color="secondary" fontSize="small" />
                    <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>MEng Fellowship Award</Typography>
                  </Box>
                </CardContent>
              </Card>

              <Card elevation={0} sx={{ borderRadius: 2, padding: 0, border: '1px solid #f0f0f0' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6"><b>Purdue University</b> | <i>West Lafayette, IN</i></Typography>
                  </Box>
                  <Box sx={{ marginTop: 0.5 }}>
                    <Typography variant="body1" sx={{ color: 'black' }}>
                      August, 2019 - May, 2023
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1rem', marginTop: 0.5 }}>
                      Bachelor of Science in Computer Science, Bachelor of Science in Data Science | Double Major
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.9rem', marginTop: 0.5 }}>
                      GPA: 3.91 / 4.0
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, marginTop: 1 }}>
                    <EmojiEventsIcon color="secondary" fontSize="small" />
                    <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
                      Dean's List (8 / 8 semesters), Semester Honor (8 / 8 semesters), DUIRI Research Fellowship, JD Global Student Scholarship
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>

        {/* Research */}
        <Box style={{ flex: 1 }}>
          <Box id="research" className="research" sx={{ marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 2 }}>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
              <b>👨‍💻 Research Experiences</b>
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Card elevation={0} sx={{ borderRadius: 2, padding: 0, border: '1px solid #f0f0f0' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>UCLA NLP | Advisor: <a href='http://web.cs.ucla.edu/~kwchang/' target="_blank" rel="noreferrer">Professor Kai-Wei Chang</a></Typography>
                  </Box>
                  <Typography variant="body1" sx={{ color: 'black' }}>
                    October, 2023 - Present
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: 1 }}>
                    <ul>
                      <li>Multimodal understanding and reasoning</li>
                    </ul>
                  </Typography>
                </CardContent>
              </Card>
              <Card elevation={0} sx={{ borderRadius: 2, padding: 0, border: '1px solid #f0f0f0' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>UCLA PLUS Lab | Advisor: <a href='https://violetpeng.github.io' target="_blank" rel="noreferrer">Professor Nanyun Peng</a></Typography>
                  </Box>
                  <Typography variant="body1" sx={{ color: 'black' }}>
                    September, 2023 - Present
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: 1 }}>
                    <ul>
                      <li>Creative Generation</li>
                      <li>Controllable Generation</li>
                    </ul>
                  </Typography>
                </CardContent>
              </Card>
              <Card elevation={0} sx={{ borderRadius: 2, padding: 0, border: '1px solid #f0f0f0' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Wharton Statistics and Data Science Department, University of Pennsylvania | Advisor: <a href='https://www.bingxinzhao.com' target="_blank" rel="noreferrer">Professor Bingxin Zhao</a></Typography>
                  </Box>
                  <Typography variant="body1" sx={{ color: 'black' }}>
                    September, 2020 - Present
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: 1 }}>
                    <ul>
                      <li>AI for biomedical scientific discovery</li>
                      <li>Large-scale data analysis</li>
                      <li>Applications for genetic data exploration</li>
                    </ul>
                  </Typography>
                </CardContent>
              </Card>
              <Card elevation={0} sx={{ borderRadius: 2, padding: 0, border: '1px solid #f0f0f0' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Daniel School of Business, Purdue University | Advisor: <a href='https://web.ics.purdue.edu/~shi178/' target="_blank" rel="noreferrer">Professor Pengyi Shi</a></Typography>
                  </Box>
                  <Typography variant="body1" sx={{ color: 'black' }}>
                    March, 2020 - Present
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: 1 }}>
                    <ul>
                      <li>AI for operations research</li>
                      <li>AI for healthcare</li>
                      <li>Applications for social good</li>
                    </ul>
                  </Typography>
                </CardContent>
              </Card>
              <Card elevation={0} sx={{ borderRadius: 2, padding: 0, border: '1px solid #f0f0f0' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Booth School of Business, University of Chicago | Advisor: <a href='https://www.chicagobooth.edu/faculty/directory/w/amy-ward' target="_blank" rel="noreferrer">Professor Amy Ward</a></Typography>
                  </Box>
                  <Typography variant="body1" sx={{ color: 'black' }}>
                    March, 2023 - Present
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: 1 }}>
                    <ul>
                      <li>AI for operations research</li>
                      <li>Applications for social good</li>
                    </ul>
                  </Typography>
                </CardContent>
              </Card>
              <Card elevation={0} sx={{ borderRadius: 2, padding: 0, border: '1px solid #f0f0f0' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Human-Centered Software Systems Lab, Purdue University | Advisor: <a href='https://tianyi-zhang.github.io' target="_blank" rel="noreferrer">Professor Tianyi Zhang</a></Typography>
                  </Box>
                  <Typography variant="body1" sx={{ color: 'black' }}>
                    May, 2020 - September, 2024
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: 1 }}>
                    <ul>
                      <li>Human-Computer Interaction</li>
                      <li>Computer Vision</li>
                    </ul>
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>

        {/* Projects */}
        <Box style={{ flex: 1 }}>
          <Box id="project" className="project" sx={{ marginLeft: 5, marginRight: 5, marginTop: 2, marginBottom: 2 }}>
            <Typography variant="h4" gutterBottom><b> 🛠️ Selected Projects</b></Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginTop: 2 }}>
              <Card elevation={0} sx={{ borderRadius: 2, padding: 0, border: '1px solid #f0f0f0' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <img src="project/chart_storytelling.png" alt="Chart Storytelling" style={{ width: 200, height: 200, marginRight: 5, borderRadius: 5 }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 5 }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Chart Storytelling</Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            padding: 1,
                            borderRadius: 5,
                            border: '4px solid #f0f0f0'
                          }}
                        >
                          <Typography variant="body2"><b>Multimodal Understanding</b></Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            padding: 1,
                            borderRadius: 5,
                            border: '4px solid #f0f0f0'
                          }}
                        >
                          <Typography variant="body2"><b>Controllable Generation</b></Typography>
                        </Box>
                      </Box>
                      <Typography variant="body1" sx={{ color: 'black' }}>
                        <ul>
                          <li>Developed Evaluation Framework: Assessed pre-trained Vision Language Models (VLMs) like GPT-4V and LLaVA on chart storytelling, focusing on generating coherent narratives from visual data.</li>
                          <li>Improved Model Performance: Fine-tuned LLaVA for better analysis and storytelling with custom prompts, boosting its ability to handle complex charts.</li>
                          <li>Conducted Case Studies: Analyzed VLM robustness and versatility across different chart types and scientific domains.</li>
                        </ul>
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, marginTop: 1 }}>
                        <Button variant="outlined" color="primary" sx={{ textTransform: 'none', fontWeight: "bold" }} size="small" href="project/Chart_Storytelling.pdf" target="_blank"> Technical Report </Button>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Card elevation={0} sx={{ borderRadius: 2, padding: 0, border: '1px solid #f0f0f0' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <img src="project/decoding.png" alt="Decoding EEG" style={{ width: 200, height: 200, marginRight: 5, borderRadius: 5 }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 5 }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Decoding Electroencephalography Brain Activity Signals with Deep Learning</Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            padding: 1,
                            borderRadius: 5,
                            border: '4px solid #f0f0f0'
                          }}
                        >
                          <Typography variant="body2"><b>Deep Learning</b></Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            padding: 1,
                            borderRadius: 5,
                            border: '4px solid #f0f0f0'
                          }}
                        >
                          <Typography variant="body2"><b>AI for Science</b></Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            padding: 1,
                            borderRadius: 5,
                            border: '4px solid #f0f0f0'
                          }}
                        >
                          <Typography variant="body2"><b>Multimodal</b></Typography>
                        </Box>
                      </Box>
                      <Typography variant="body1" sx={{ color: 'black' }}>
                        <ul>
                          <li>Developed Data Augmentation Strategies: Implemented spectrogram transformation, clustering-based augmentation, and subsampling with noise to enhance EEG data classification accuracy.</li>
                          <li>Explored Multiple Deep Learning Models: Trained CNN, RNN, and hybrid models (CNN+LSTM, CNN+GRU), achieving 73% test accuracy with CNN and 70% with CNN+GRU on combined subject data.</li>
                          <li>Performed Cross-Validation: Improved model generalizability across subjects, refining hyperparameters and optimizing performance with cross-validation techniques.</li>
                        </ul>
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, marginTop: 1 }}>
                        <Button variant="outlined" color="primary" sx={{ textTransform: 'none', fontWeight: "bold" }} size="small" href="project/Decoding_Electroencephalography_Brain_Activity_Signals_with_Deep_Learning.pdf" target="_blank"> Technical Report </Button>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Card elevation={0} sx={{ borderRadius: 2, padding: 0, border: '1px solid #f0f0f0' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                    <img src="project/postman.png" alt="POSTMAN" style={{ width: 200, height: 200, marginRight: 5, borderRadius: 5 }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 5 }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>POSTMAN: Task-Based Delivery Platform for Fast Errand Solutions </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            padding: 1,
                            borderRadius: 5,
                            border: '4px solid #f0f0f0'
                          }}
                        >
                          <Typography variant="body2"><b>Full-Stack Development</b></Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            padding: 1,
                            borderRadius: 5,
                            border: '4px solid #f0f0f0'
                          }}
                        >
                          <Typography variant="body2"><b>Human-Computer Interaction</b></Typography>
                        </Box>
                      </Box>
                      <Typography variant="body1" sx={{ color: 'black' }}>
                        <ul>
                          <li>Designed UI with MUI in Figma. Developed web page based on React framework. Implement real-time map with Google Map API.</li>
                          <li>Implemented authorization and chatting functions. Used Express as server to interact with MongoDB cluster database and configured Redux store for data operation.</li>
                        </ul>
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, marginTop: 1 }}>
                        <Button variant="outlined" color="primary" sx={{ textTransform: 'none', fontWeight: "bold" }} size="small" href="https://github.com/fastguys/PostManWeb" target="_blank"> Code </Button>
                        <Button variant="outlined" color="primary" sx={{ textTransform: 'none', fontWeight: "bold" }} size="small" href="https://github.com/fastguys/PostManWeb" target="_blank"> Documentation </Button>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>

        {/* Footer */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 2, marginTop: 2 }}>
          <Typography variant="body2" sx={{ color: 'grey' }}>
            © 2025 bingxuanli.com
          </Typography>
        </Box>

      </Box>
    </ThemeProvider>
  );
}

export default App;